import {deleteCookie, getCookie, setCookie} from "./jwtCookie";

const authCookieName = 'jwtToken'
const preAuthCookieName = '2faToken'
const authCookieLifespanMinutes = 15;

const login = async (
    credentials: { username: string; password: string },
    onLoginSuccess: () => void,
): Promise<number> => {
    try {
        const response = await fetch('/api/auth/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(credentials),
        });

        if (response.ok) {
            const data = await response.json()
            if (data.two_fa_token) {
                setCookie(preAuthCookieName, data.two_fa_token, authCookieLifespanMinutes);
                onLoginSuccess();
            }
            return 0
        } else {
            console.error('Login failed')
            return response.status
        }
    } catch (error) {
        console.error('Failed to fetch:', error)
        return 0
    }
}

const login2FAVErification = async (
    credentials: { code: string, token: string },
    on2FAVerified: () => void,
) => {
    try {
        const token = getCookie(preAuthCookieName);

        if (token == null) {
            console.error('Need authentication token');

            return 0;
        }

        credentials.token = token

        const response = await fetch('/api/auth/verify-2fa', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(credentials),
        });

        if (response.ok) {
            const data = await response.json();
            setCookie(authCookieName, data.token, authCookieLifespanMinutes);
            on2FAVerified();
            return response.status
        } else {
            console.error('2FA verification failed');
            return response.status
        }
    } catch (error) {
        console.error('Failed to verify 2FA:', error);
        return 0
    }
}

const logout = () => deleteCookie(authCookieName)

const checkProtectedResource = async (onSuccess: () => void, onFailure: () => void) => {
    const jwtToken = getCookie(authCookieName)
    if (!jwtToken) return;

    try {
        const response = await fetch('/api/auth/protected', {
            headers: {
                'Authorization': `Bearer ${jwtToken}`,
                'Content-Type': 'application/json',
            },
        });
        if (response.ok) {
            const data = await response.json()
            if (data.token) {
                setCookie("jwtToken", data.token, authCookieLifespanMinutes)
            }
            onSuccess();
            return 1;
        } else if (response.status >= 400 && response.status < 500) {
            console.error('Access denied, showing login form')
            deleteCookie("jwtToken")
            onFailure();
            return 0;
        } else {
            console.error('Failed to access protected resource');
            return 0;
        }
    } catch (error) {
        console.error('Error accessing protected resource:', error);
        return 0;
        return 0;
    }
};

export {
    checkProtectedResource,
    login,
    logout,
    login2FAVErification
};