import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {Typography} from "@mui/material";
import {getCookie} from "../hooks/jwtCookie";

interface RTBalanceInterface {
    totalBalanceB1: string | number | null;
    totalPaymentAmountB1: string | number | null;
    totalBalanceB2: string | number | null;
    totalPaymentAmountB2: string | number | null;
}

const WelcomePage = () => {
    const [rtBalances, setRTBalances] = useState<RTBalanceInterface>({
        totalBalanceB1: "0",
        totalBalanceB2: "0",
        totalPaymentAmountB1: "0",
        totalPaymentAmountB2: "0",
    });
    useEffect(() => {
        const loadRealTimeBalances = async () => {
            const jwtToken = getCookie('jwtToken');
            const response = await fetch('/api/dashboard/realtime-payments', {
                headers: {
                    'Authorization': `Bearer ${jwtToken}`,
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                const data = await response.json();

                setRTBalances(data);
            } else {
                console.error('Failed to fetch records');
            }
        };

        loadRealTimeBalances();
    }, []);
    const formatAmount = (amount: string | number | null): string => {
        if (!amount) return '0.00';

        if (typeof amount === 'number') {
            amount = amount.toString();
        }

        amount = amount.replace(',', '.');
        const parts = amount.split('.');
        const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' '); // Форматирование разделителей тысяч
        const decimalPart = parts[1] ? parts[1].padEnd(2, '0') : '00'; // Всегда два знака после точки
        return `${integerPart}.${decimalPart}`;
    };

    return (
        <Box
            sx={{
                p: 2,
                py: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                textAlign: 'left',
            }}
        >
            <Card variant="outlined" sx={{maxWidth: 360}}>
                <Box sx={{p: 0}}>

                    <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14,p: 2 }}>
                        Балансы на текущий день
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table aria-label="table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Банк</TableCell>
                                    <TableCell>Баланс</TableCell>
                                    <TableCell>Отправлено</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>BB</TableCell>
                                    <TableCell>{formatAmount(rtBalances?.totalBalanceB1 || "0")}</TableCell>
                                    <TableCell>{formatAmount(rtBalances?.totalPaymentAmountB1 || "0")}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>BQ</TableCell>
                                    <TableCell>{formatAmount(rtBalances?.totalBalanceB2 || "0")}</TableCell>
                                    <TableCell>{formatAmount(rtBalances?.totalPaymentAmountB2 || "0")}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Card>
            {/*<Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>*/}
            {/*    Баланс на Б1*/}
            {/*</Typography>*/}
            {/*<Typography variant="h5" component="div">*/}
            {/*    {formatAmount(484841473.77)}*/}
            {/*</Typography>*/}
            {/*<Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>*/}
            {/*    Баланс на Б2*/}
            {/*</Typography>*/}
            {/*<Typography variant="h5" component="div">*/}
            {/*    {formatAmount(82009500.00)}*/}
            {/*</Typography>*/}
        </Box>
    );
};

export default WelcomePage;