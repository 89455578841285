import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ScrollDialog from "../components/UploadRegistryDialog";
import {Button, Chip} from "@mui/material";
import {DialogProps} from "@mui/material/Dialog";

const formatAmount = (amount: string | number | null): string => {
    if (!amount) return '0.00';

    if (typeof amount === 'number') {
        amount = amount.toString();
    }

    amount = amount.replace(',', '.');

    let num = parseFloat(amount);
    if (isNaN(num)) return '0.00'; // Handle invalid input

    // Round to two decimal places
    amount = num.toFixed(2);

    const parts = amount.split('.');
    const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' '); // Форматирование разделителей тысяч
    const decimalPart = parts[1] ? parts[1].padEnd(2, '0') : '00'; // Всегда два знака после точки

    return `${integerPart}.${decimalPart}`;
};

interface ReportItemInterface {
    id: string | number;
    status: string;
    content: [string[], ...string[][]];
    createdAt: string;
}


const BankReportPage: React.FC = () => {
    const [records, setRecords] = useState<ReportItemInterface[]>([]);

    const reportApprove = async (id: string, code: string, afterApprove: () => void) => {
        const jwtToken = document.cookie.split('; ').find(row => row.startsWith('jwtToken='))?.split('=')[1];
        const response = await fetch('api/bankreport/approve', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${jwtToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                id: parseInt(id),
                code: code,
            }),
        });

        if (!response.ok) {
            throw new Error('Ошибка при подтверждении.');
        } else if (typeof afterApprove == 'function') {
            afterApprove();
        }
    }

    function Row(props: { row: ReportItemInterface, onDownload?: (id: number | string) => void | undefined }) {
        const {row, onDownload} = props;
        const [open, setOpen] = React.useState(false);

        return (
            <React.Fragment>
                <TableRow sx={{'& > *': {borderBottom: 'unset'}}}>
                    <TableCell>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                        </IconButton>
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {row.createdAt}
                    </TableCell>
                    <TableCell>{
                        formatAmount(
                            row.content.reduce((accumulator, currentRow) => {
                                return accumulator + Math.abs(parseFloat(currentRow[2]) || 0);
                            }, 0))
                    }</TableCell>
                    <TableCell>{
                        formatAmount(
                            row.content.reduce((accumulator, currentRow) => {
                                return accumulator + Math.abs(parseFloat(currentRow[3]) || 0);
                            }, 0))
                    }</TableCell>
                    <TableCell>{
                        formatAmount(
                            row.content.reduce((accumulator, currentRow) => {
                                return accumulator + Math.abs(parseFloat(currentRow[4]) || 0);
                            }, 0))
                    }</TableCell>
                    <TableCell>{
                        formatAmount(
                            row.content.reduce((accumulator, currentRow) => {
                                return accumulator + Math.abs(parseFloat(currentRow[5]) || 0);
                            }, 0))
                    }</TableCell>
                    <TableCell>{
                        formatAmount(
                            row.content.reduce((accumulator, currentRow) => {
                                return accumulator + Math.abs(parseFloat(currentRow[6]) || 0);
                            }, 0))
                    }</TableCell>
                    <TableCell>{
                        formatAmount(
                            row.content.reduce((accumulator, currentRow) => {
                                return accumulator + Math.abs(parseFloat(currentRow[7]) || 0);
                            }, 0))
                    }</TableCell>
                    <TableCell>
                        {
                            (row.status !== 'approved') ? (
                                <Button variant="outlined" href="#outlined-buttons"
                                        onClick={() => reportApprove(row.id.toString(), "", fetchRegistry)}>
                                    Подтвердить
                                </Button>
                            ) : (
                                <Chip label="Подтвержден" color="success" variant="outlined"/>
                            )
                        }
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={9}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{margin: 1}}>
                                <Typography variant="h6" gutterBottom component="div">
                                    Реестр от {row.createdAt} <Button variant="outlined" href="#outlined-buttons"
                                                                      onClick={() => onDownload ? onDownload(row.id) || undefined : undefined}>
                                    Скачать
                                </Button>
                                </Typography>
                                <Table size="small" aria-label="purchases">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Дата</TableCell>
                                            <TableCell>BIN</TableCell>
                                            <TableCell>Сумма начала дня</TableCell>
                                            <TableCell>Входящие</TableCell>
                                            <TableCell>Исходящие</TableCell>
                                            <TableCell>Комиссия</TableCell>
                                            <TableCell>Возврат</TableCell>
                                            <TableCell>Сумма на конец дня</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {row.content.filter((item) => item[0] !== 'date').map((historyRow, index: number) => (
                                            <TableRow key={index}>
                                                <TableCell>{historyRow[0]}</TableCell>
                                                <TableCell component="th" scope="row">{historyRow[1]}</TableCell>
                                                <TableCell>{formatAmount(historyRow[2])}</TableCell>
                                                <TableCell>{formatAmount(historyRow[3])}</TableCell>
                                                <TableCell>{formatAmount(historyRow[4])}</TableCell>
                                                <TableCell>{formatAmount(historyRow[5])}</TableCell>
                                                <TableCell>{formatAmount(historyRow[6])}</TableCell>
                                                <TableCell>{formatAmount(historyRow[7])}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        );
    }

    const fileDownloader = (id: number | string) => {
        let item = records.filter((x: any) => x.id.toString() === id.toString());

        if (item.length < 1) {
            return;
        }

        const csvRows = item[0].content.map((row: string[]) => {
            if (row[1] !== 'bin' && row[1].length > 0) {
                row[1] = "'" + row[1];
            }
            return row.join(';')
        }).join('\n');

        const blob = new Blob([csvRows], {type: 'text/csv'});
        const url = URL.createObjectURL(blob);
        const downloadLink = document.createElement('a');
        downloadLink.href = url;
        downloadLink.download = `day_off_report_${item[0].createdAt}.csv`; // Set the desired filename

        downloadLink.click();
    };

    const fetchRegistry = async (): Promise<void> => {
        const jwtToken = document.cookie.split('; ').find(row => row.startsWith('jwtToken='))?.split('=')[1];
        const response = await fetch('api/bankreport/list', {
            headers: {
                'Authorization': `Bearer ${jwtToken}`,
                'Content-Type': 'application/json',
            },
        });

        if (response.ok) {
            const data = await response.json();
            setRecords(data);
        } else {
            console.error('Failed to fetch records');
        }
    };

    useEffect(() => {
        fetchRegistry();
    }, []);

    return (
        <Box sx={{p: 2}}>
            <Typography variant="h4" gutterBottom component="div">
                Реестры закрытия дня
            </Typography>
            <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <TableCell/>
                            <TableCell>Дата</TableCell>
                            <TableCell>Сумма начала дня</TableCell>
                            <TableCell>Входящие</TableCell>
                            <TableCell>Исходящие</TableCell>
                            <TableCell>Комиссия</TableCell>
                            <TableCell>Возврат</TableCell>
                            <TableCell>Сумма конца дня</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {records.map((row, index) => (
                            <Row key={index} row={row} onDownload={fileDownloader}/>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default BankReportPage;