import React, { useState } from 'react';
import {
    Button,
    TextField,
    Container,
    Typography,
    Box,
} from '@mui/material';

interface LoginFormProps {
    onLogin: (credentials: { username: string; password: string }) => Promise<number>;
}

const LoginForm: React.FC<LoginFormProps> = ({ onLogin }) => {
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [error, setError] =  useState<string>('');
    const [badRequest, setBadRequest] = useState<boolean>(false)

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        const code = await onLogin({ username, password });
        if (code >= 400 && code < 500) {
            setError('Неправильные имя пользователя или пароль')
            setBadRequest(true)
        } else if (code >= 500) {
            setError('Что--то пошло не так. Пожалуйста, обновите страницу.')
        }
    };

    const handleUsernameChange  = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setUsername(e.target.value);
        setError('')
        setBadRequest(false)
    }
    const handlePasswordChange  = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setPassword(e.target.value);
        setError('')
        setBadRequest(false)
    }

    return (
        <Container maxWidth="sm">
            <Box sx={{ mt: 5 }}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Вход
                </Typography>
                <form onSubmit={handleSubmit}>
                    <TextField
                        label="Имя пользователя"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={username}
                        error={!!error}
                        onChange={handleUsernameChange}
                    />
                    <TextField
                        label="Пароль"
                        type="password"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={password}
                        error={!!error}
                        helperText={error}
                        onChange={handlePasswordChange}
                    />
                    <Button type="submit" variant="contained" color="primary" fullWidth disabled={badRequest || !username || !password}>
                        Войти
                    </Button>
                </form>
            </Box>
        </Container>
    );
};

export default LoginForm;