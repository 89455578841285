import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ScrollDialog from "../components/UploadRegistryDialog";

interface RepositoryItem {
    date: string;
    bin: string;
    sum_day_start: number;
}

interface ItemRecord {
    content: string;
    createdAt: string;
    name: string;
    status: string;
}

function createData(
    date: string,
    status: string,
    content: RepositoryItem[]
) {
    return {
        date,
        status,
        content: content
    };
}

const formatAmount = (amount: string | number | null): string => {
    if (!amount) return '0.00';

    if (typeof amount === 'number') {
        amount = amount.toString();
    }

    amount = amount.replace(',', '.');
    const parts = amount.split('.');
    const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' '); // Форматирование разделителей тысяч
    const decimalPart = parts[1] ? parts[1].padEnd(2, '0') : '00'; // Всегда два знака после точки
    return `${integerPart}.${decimalPart}`;
};

function Row(props: { row: ReturnType<typeof createData> }) {
    const {row} = props;
    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment>
            <TableRow sx={{'& > *': {borderBottom: 'unset'}}}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.date}
                </TableCell>
                <TableCell>{
                    formatAmount(
                        row.content.reduce((accumulator, currentRow) => {
                            return accumulator + currentRow.sum_day_start;
                        }, 0))
                }</TableCell>
                <TableCell colSpan={2}>{row.status}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{margin: 1}}>
                            <Typography variant="h6" gutterBottom component="div">
                                Реестр от {row.date}
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Date</TableCell>
                                        <TableCell>Bin</TableCell>
                                        <TableCell>Amount</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.content.map((historyRow, index) => (
                                        <TableRow key={index}>
                                            <TableCell component="th" scope="row">
                                                {historyRow.date}
                                            </TableCell>
                                            <TableCell>{historyRow.bin}</TableCell>
                                            <TableCell>{formatAmount(historyRow.sum_day_start)}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

const ImportRegistryPage: React.FC = () => {
    const [records, setRecords] = useState<any[]>([]);

    const parseFileContent = (content: string): RepositoryItem[] => {
        const rows = content.trim().split('\n').slice(1); // Split by lines and skip header
        if (!rows.length) throw new Error("Файл пустой или имеет неправильный формат.");

        const parsed: RepositoryItem[] = rows.map(row => {
            let [rawDate, rawBin, rawSum] = row.trim().split(';').map(cell => cell.trim());
            if (!rawDate || !rawBin || !rawSum) {
                throw new Error("Некорректная строка формата: отсутствуют дата, BIN или сумма.");
            }

            const date = rawDate;
            const bin = rawBin.replace("'", "");
            const sum_day_start = parseFloat(rawSum.replace(/,/g, ''));
            if (isNaN(sum_day_start)) throw new Error("Некорректный формат суммы.");

            return {
                date,
                bin,
                sum_day_start,
            };
        });

        return parsed;
    };

    const parseRecord = (items: ItemRecord[]) => {
        const response = []

        for (let item of items) {
            const date = new Date(parseInt(item.createdAt) * 1000);
            const formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
            response.push(
                createData(
                    formattedDate,
                    item.status,
                    parseFileContent(item.content),
                )
            );
        }

        return response;
    };

    const fetchRegistry = async (): Promise<void> => {
        const jwtToken = document.cookie.split('; ').find(row => row.startsWith('jwtToken='))?.split('=')[1];
        const response = await fetch('/api/registry-file/list', {
            headers: {
                'Authorization': `Bearer ${jwtToken}`,
                'Content-Type': 'application/json',
            },
        });

        if (response.ok) {
            const data = await response.json();
            setRecords(parseRecord(data));
        } else {
            console.error('Failed to fetch records');
        }
    };

    useEffect(() => {
        fetchRegistry();
    }, []);

    return (
        <Box sx={{p: 2}}>
            <Typography variant="h4" gutterBottom component="div">
                Входящие реестры
            </Typography>
            <ScrollDialog afterUpload={fetchRegistry} />
            <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <TableCell/>
                            <TableCell>Дата загрузки</TableCell>
                            <TableCell>Сумма</TableCell>
                            <TableCell>Статус</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {records.map((row, index) => (
                            <Row key={index} row={row}/>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default ImportRegistryPage;