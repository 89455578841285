import React, {useEffect, useState} from 'react'
import Dashboard from './components/Dashboard2'
import LoginForm from './components/LoginForm'
import {checkProtectedResource, login, login2FAVErification, logout} from "./hooks/auth"
import TwoFactorAuth from "./components/TwoFactorAuth";

const App: React.FC = () => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
    const [is2FAVerified, setIs2FAVerified] = useState<boolean>(false)

    const handleLogin = async (credentials: { username: string; password: string }) =>
        login(credentials, () => setIsAuthenticated(true))

    const handle2FA = async (credentials: { code: string, token: string }) =>
        login2FAVErification(credentials, () => setIs2FAVerified(true))

    const handleLogout = () => {
        logout()
        setIsAuthenticated(false)
    }


    useEffect(() => {
        checkProtectedResource(() => {
                setIsAuthenticated(true);
                setIs2FAVerified(true);
            },
            () => {
                setIsAuthenticated(false);
                setIs2FAVerified(false);
            }
        )

        const interval = setInterval(
            () => {
                checkProtectedResource(() => {
                        setIsAuthenticated(true);
                        setIs2FAVerified(true);
                    },
                    () => {
                        setIsAuthenticated(false);
                        setIs2FAVerified(false);
                    }
                );
            },
            60000
        );

        return () => clearInterval(interval);
    }, []);

    if (!isAuthenticated) {
        return <LoginForm onLogin={handleLogin}/>
    } else if (isAuthenticated && !is2FAVerified) {
        return <TwoFactorAuth on2Fa={handle2FA}/>
    }

    return <Dashboard onLogout={handleLogout}/>
};

export default App;