import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog, {DialogProps} from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Alert from '@mui/material/Alert';

export default function ScrollDialog({afterUpload = async (): Promise<void> => {}}) {
    const [open, setOpen] = React.useState(false);
    const [scroll, setScroll] = React.useState<DialogProps['scroll']>('paper');
    const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
    const [fileContent, setFileContent] = React.useState<string | null>(null);
    const [parsedData, setParsedData] = React.useState<object[] | null>(null);
    const [error, setError] = React.useState<string | null>(null); // State for error handling

    const handleClickOpen = (scrollType: DialogProps['scroll']) => () => {
        setOpen(true);
        setScroll(scrollType);
        setError(null); // Reset error state on dialog open
    };

    const handleClose = () => {
        setOpen(false);
    };

    const descriptionElementRef = React.useRef<HTMLElement>(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    const formatAmount = (amount: string | number | null): string => {
        if (!amount) return '0.00';

        if (typeof amount === 'number') {
            amount = amount.toString();
        }

        amount = amount.replace(',', '.');
        const parts = amount.split('.');
        const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' '); // Форматирование разделителей тысяч
        const decimalPart = parts[1] ? parts[1].padEnd(2, '0') : '00'; // Всегда два знака после точки
        return `${integerPart}.${decimalPart}`;
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setSelectedFile(file);
            readFileContent(file); // Read the file content when file is selected
        }
    };

    const readFileContent = (file: File) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const text = e.target?.result as string;
            setFileContent(text); // Store the file content in the state
            try {
                const parsed = parseFileContent(text); // Parse the content into the desired format
                setParsedData(parsed);
                setError(null); // Clear any previous error
            } catch (e) {
                setParsedData(null); // Clear data if there is an error
                setError((e as Error).message); // Set error message with details
            }
        };
        reader.readAsText(file); // You can choose other methods like readAsArrayBuffer or readAsDataURL if needed
    };

    // Function to parse file content into the required array of objects
    const parseFileContent = (content: string) => {
        const rows = content.split('\n').slice(1); // Split by lines and skip header
        if (!rows.length) throw new Error("Файл пустой или имеет неправильный формат."); // Check if there is any data

        const parsed = rows
            .filter(item => item !== '')
            .map(row => {
                const [rawDate, rawBin, rawSum] = row.split(';').map(cell => cell.trim());

                if (!rawDate || !rawBin || !rawSum) {
                    throw new Error("Некорректная строка формата: отсутствуют дата, BIN или сумма.");
                } // Check if all required fields are present
                const date = rawDate; // Format date as YYYY-MM-DD
                const bin = rawBin.replace("'", ""); // Remove single quote from BIN
                const sum_day_start = parseFloat(rawSum.replace(/,/g, '')); // Convert sum to number, remove commas
                if (isNaN(sum_day_start)) throw new Error("Некорректный формат суммы: не удается преобразовать в число."); // Validate sum
                return {
                    date,
                    bin,
                    sum_day_start,
                };
            })
            .filter(Boolean); // Remove any null entries
        return parsed;
    };

    const handleFileUpload = async () => {
        if (selectedFile && fileContent) {
            try {
                const jwtToken = document.cookie.split('; ').find(row => row.startsWith('jwtToken='))?.split('=')[1];
                const response = await fetch('/api/registry-file/create', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${jwtToken}`,
                    },
                    body: JSON.stringify({
                        name: selectedFile.name,
                        content: fileContent,
                    }),
                });

                if (!response.ok) {
                    throw new Error('Ошибка при загрузке файла.');
                }

                // Очистка состояния или вывод сообщения об успешной загрузке
                afterUpload();
                clearFileData(); // Очистить данные о файле после загрузки
                setOpen(false); // Закрыть диалоговое окно
            } catch (e) {
                setError((e as Error).message);
                console.error("Ошибка загрузки:", e);
            }
        } else {
            setError("Файл не выбран или его содержимое пусто.");
        }
    };

    const clearFileData = () => {
        setSelectedFile(null);
        setFileContent(null);
        setParsedData(null);
    };

    return (
        <React.Fragment>
            <Button variant="outlined" onClick={handleClickOpen('paper')}>Загрузить реестр</Button>
            <Dialog
                open={open}
                fullWidth={true}
                onClose={handleClose}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">Загрузка реестра</DialogTitle>
                <DialogContent dividers={scroll === 'paper'}>
                    <DialogContentText
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                    >
                        <label htmlFor="upload-button-file">
                            <input
                                style={{ display: 'none' }}
                                id="upload-button-file"
                                type="file"
                                onChange={handleFileChange}
                            />
                            <Button variant="contained" component="span">
                                <CloudUploadIcon />&nbsp;&nbsp;&nbsp;Открыть файл
                            </Button>
                        </label>
                        {selectedFile && (
                            <p>Выбранный файл: {selectedFile.name}</p>
                        )}
                    </DialogContentText>
                    {error && (
                        <Alert severity="error">{error}</Alert>
                    )}
                    {parsedData && (
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Дата</TableCell>
                                    <TableCell>BIN</TableCell>
                                    <TableCell>Сумма</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {parsedData ? (
                                    <TableRow>
                                        <TableCell>-</TableCell>
                                        <TableCell><b>Total</b></TableCell>
                                        <TableCell>{formatAmount(parsedData.reduce((accumulator, currentRow) => {
                                            //@ts-ignore
                                            return accumulator + currentRow.sum_day_start;
                                        }, 0))}</TableCell>
                                    </TableRow>
                                ) : ''}
                                {parsedData.map((row: any, index: number) => (
                                    <TableRow key={index}>
                                        <TableCell>{row.date}</TableCell>
                                        <TableCell>{row.bin}</TableCell>
                                        <TableCell>{formatAmount(row.sum_day_start)}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Отменить</Button>
                    <Button onClick={handleFileUpload}>Загрузить</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}