import React, { useState } from 'react';
import { Button, TextField, Chip, Container, Typography, Box } from '@mui/material';

interface TwoFactorAuthFormProps {
    on2Fa: (credentials: { code: string, token: string }) => Promise<number>;
}

const TwoFactorAuth: React.FC<TwoFactorAuthFormProps> = ({ on2Fa }) => {
    const [twoFaCode, setTwoFaCode] = useState<string>('');
    const [counter, setCounter] = React.useState(Math.ceil(((new Date().getTime())+(5*60*1000)) / 1000) - Math.ceil(new Date().getTime() / 1000));
    const [error, setError] = useState<string>('');
    const [badRequest, setBadRequest] = useState<boolean>(false)

    React.useEffect(() => {
        const timer =
            counter > 0 ? setInterval(() => setCounter(counter - 1), 1000) : 0;
        return () => clearInterval(timer);
    }, [counter]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        const code = await on2Fa({code: twoFaCode, token: ""});
        if (code >= 400 && code < 500) {
            setError('Неправильный код')
            setBadRequest(true)
        } else if (code >= 500) {
            setError('Что--то пошло не так. Пожалуйста, обновите страницу.')
        }
    };

    const toFancyTimer = (counter: number): string => {
        const minutes = Math.floor(counter  / 60);
        const seconds = counter % 60;
        return `${minutes}:${seconds < 10? '0' + seconds : seconds}`;
    }

    const onCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTwoFaCode(e.target.value)
        setError('')
        setBadRequest(false)
    }

    return (
        <Container maxWidth="sm">
            <Box sx={{mt: 5}}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Введите код из СМС
                </Typography>
                <Chip
                    label={
                        counter > 0
                            ? "Срок действия кода: " + toFancyTimer(counter)
                            : "Срок действия кода истек. Пожалуйста, обновите страницу."
                    }
                    variant="outlined"
                    color={counter > 0 ? "warning" : "error"}
                />
                <form onSubmit={handleSubmit}>
                    <TextField
                        label="Код"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={twoFaCode}
                        onChange={onCodeChange}
                        error={!!error}
                        helperText={error}
                        disabled={counter <= 0}
                    />
                    <Button sx={{mt: 2}} type="submit" size="large" variant="contained" color="primary" fullWidth disabled={counter <= 0 || !twoFaCode || !!error}>
                        Подтвердить
                    </Button>
                </form>
            </Box>
        </Container>
    );
};

export default TwoFactorAuth;